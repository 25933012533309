(function($){
  ('use strict');

  // Sticky Header
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 120) {
      $('.navbar-area').addClass('is-sticky');
    } else {
      $('.navbar-area').removeClass('is-sticky');
    }
  });

  // Button Hover JS
  $('.default-btn')
    .on('mouseenter', function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find('span').css({ top: relY, left: relX });
    })
    .on('mouseout', function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find('span').css({ top: relY, left: relX });
    });

  $('.optional-btn')
    .on('mouseenter', function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find('span').css({ top: relY, left: relX });
    })
    .on('mouseout', function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find('span').css({ top: relY, left: relX });
    });

  // Banner Content Slides
  $('.banner-content-slides').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    autoHeight: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>",
    ],
  });

  // Partner Slides
  $('.partner-slides').owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 50,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  });

  // Odometer JS
  $('.odometer').appear(function (e) {
    var odo = $('.odometer');
    odo.each(function () {
      var countNumber = $(this).attr('data-count');
      $(this).html(countNumber);
    });
  });

  // Popup Image
  $('.popup-btn').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
    },
  });

  // Popup Video
  $('.popup-youtube').magnificPopup({
    disableOn: 320,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
  });

  // WOW JS
  if ($('.wow').length) {
    var wow = new WOW({
      boxClass: 'wow', // animated element css class (default is wow)
      animateClass: 'animated', // animation css class (default is animated)
      offset: 20, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
    });
    wow.init();
  }

  // Pricing tabs
  $('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
  $('.tab ul.tabs li a').on('click', function (g) {
    var tab = $(this).closest('.tab'),
      index = $(this).closest('li').index();
    tab.find('ul.tabs > li').removeClass('current');
    $(this).closest('li').addClass('current');
    tab
      .find('.tab_content')
      .find('div.tabs_item')
      .not('div.tabs_item:eq(' + index + ')')
      .slideUp();
    tab
      .find('.tab_content')
      .find('div.tabs_item:eq(' + index + ')')
      .slideDown();
    g.preventDefault();
  });

  // Feedback Slides
  $('.feedback-slides').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  });

  // Case Studies Slides
  $('.case-studies-slides').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  });

  // Blog Slides
  $('.blog-slides').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  });

  // Testimonials Slides
  $('.testimonials-slides-two').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    items: 1,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
  });

  // Banner Image Slides
  $('.banner-image-slider').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    autoplayTimeout: 2500,
    autoHeight: true,
    items: 1,
    animateOut: 'fadeOut',
  });

  // Tooltip JS
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Testimonials Slides
  $('.testimonials-slides').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  });

  // FAQ Accordion
  $('.accordion')
    .find('.accordion-title')
    .on('click', function () {
      // Adds Active Class
      $(this).toggleClass('active');
      // Expand or Collapse This Panel
      $(this).next().slideToggle('fast');
      // Hide The Other Panels
      $('.accordion-content').not($(this).next()).slideUp('fast');
      // Removes Active Class From Other Titles
      $('.accordion-title').not($(this)).removeClass('active');
    });

  // Scroll Event
  $(window).on('scroll', function () {
    var scrolled = $(window).scrollTop();
    if (scrolled > 600) $('.go-top').addClass('active');
    if (scrolled < 600) $('.go-top').removeClass('active');
  });
  // Click Event
  $('.go-top').on('click', function () {
    $('html, body').animate({ scrollTop: '0' }, 500);
  });

  // IS Banner Slides
  $('.is-banner-slides').owlCarousel({
    nav: true,
    loop: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1500,
    autoHeight: true,
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>",
    ],
  });

  // IS Services Slides
  $('.is-services-slides').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    margin: 30,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  });

  // IS Services Slides
  $('.is-testimonials-slides').owlCarousel({
    loop: true,
    nav: false,
    margin: 30,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  });
}(jQuery));
